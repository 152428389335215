import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
  ReplaySubject,
  share,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';

import { TokenService } from '../core/token.service';
import { User } from './user';

export interface SignInData {
  login: string;
  token: string;
  sesame_token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userPreferences = new BehaviorSubject<User | null>(null);

  get userPreferences(): Observable<User | null> {
    return this._userPreferences.asObservable();
  }

  readonly isAuthenticated = this.token.token.pipe(
    distinctUntilChanged(),
    map(token => !!token)
  );

  readonly user = this.isAuthenticated.pipe(
    switchMap(isAuthenticated => (isAuthenticated ? this.getUser() : of(null))),
    share({
      connector: () => new ReplaySubject(0),
      resetOnComplete: true,
      resetOnError: true,
      resetOnRefCountZero: true,
    })
  );

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  getUser(): Observable<User> {
    return this.http.get<User>(`${environment.api}/user-active`).pipe(
      tap(user => {
        this._userPreferences.next(user);
      }),
      map(permission => {
        if (permission.maintenance) {
          this.router.navigate(['./maintenance']);
        }
        return permission;
      })
    );
  }

  userHasPermission(permission: string): Observable<boolean> {
    return this.user.pipe(
      map(user => !!user && user.permissions.includes(permission.trim()))
    );
  }

  userHasSomePermissions(permissions: string[]): Observable<boolean> {
    return combineLatest(
      permissions.map(permission => this.userHasPermission(permission))
    ).pipe(
      map(hasPermissions =>
        hasPermissions.some(hasPermission => hasPermission === true)
      )
    );
  }
  ssoInit(): Observable<{ redirectUrl: string }> {
    return this.http.get<{ redirectUrl: string }>(
      `${environment.api}/sso/initLogin`
    );
  }

  ssoCallback(): Observable<SignInData> {
    const sesameToken = this.route.snapshot.queryParamMap.get('sesame_token');
    const dvToken = this.route.snapshot.queryParamMap.get('dv_token');
    if (sesameToken && dvToken) {
      return this.http
        .get<SignInData>(`${environment.api}/sso/callback`, {
          params: { sesame_token: sesameToken, dv_token: dvToken },
          withCredentials: true,
        })
        .pipe(
          tap(({ token, sesame_token }) => {
            this.token.set(token, sesame_token);
          })
        );
    } else throw new Error('Missing sesame_token or dv_token');
  }

  signIn(login: string, password: string): Observable<SignInData> {
    const body = new FormData();
    body.append('login', login);
    body.append('password', btoa(password));
    const httpOptions = {
      withCredentials: true,
    };

    return this.http
      .post<SignInData>(`${environment.api}/login`, body, httpOptions)
      .pipe(
        tap(({ token, sesame_token }) => {
          this.token.set(token, sesame_token);
        })
      );
  }

  signInAndRemember(login: string, password: string): Observable<SignInData> {
    return this.signIn(login, password).pipe(
      tap(({ token, sesame_token }) => {
        this.token.persist(token, sesame_token);
      })
    );
  }

  logout(): void {
    this.token.remove();
    this.router.navigate(['/sign-in']);
  }
}
