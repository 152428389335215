import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

import { TokenService } from '../token.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private token: TokenService,
    private router: Router,
    public snack: MatSnackBar
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (this.isApiRequest(request)) {
          if (response.status === 401) {
            this.token.remove();
            this.router.navigate(['/sign-in'], {
              queryParams: {
                redirectTo: this.router.routerState.snapshot.url,
              },
            });
          } else {
            this.printErrorMessage(response);
          }
        }
        return throwError(() => response);
      })
    );
  }

  private printErrorMessage(response: HttpErrorResponse): void {
    const title = response.error['hydra:title'] || 'Error';
    const description =
      response.error['hydra:description'] ||
      response.error.msg ||
      'something went wrong';
    this.snack.open(`${title} : ${description}`, 'OK', {
      panelClass: 'error-snack-bar',
    });
  }

  private isApiRequest(request: HttpRequest<unknown>): boolean {
    return request.url.startsWith(environment.api);
  }
}
