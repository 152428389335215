<div class="timer-container">
  <div style="line-height: 15px; font-size: 15px; margin-left: 8px">
    Rule execution info
  </div>
  <button
    (mouseover)="isOpen = true"
    (mouseout)="isOpen = false"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    mat-icon-button
    aria-label="next rule execution">
    <mat-icon>{{ !error ? 'update' : 'warning' }}</mat-icon>
  </button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen">
    <div
      class="timer"
      *ngIf="!error && (timeLeft$ | async) as t; else errorTemplate"
      [ngClass]="calculateClasses()">
      <div>
        <span id="hours"> {{ t.hoursToExecution }} </span>hrs
        <span id="minutes"> {{ t.minutesToExecution }} </span>min
        <span id="seconds"> {{ t.secondsToExecution }} </span>s<br />
      </div>
      <div>until next rules deployed</div>
    </div>

    <ng-template
      #errorTemplate
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen">
      <div class="timer">{{ error }}</div>
    </ng-template>
  </ng-template>

  <button
    [disabled]="!!error"
    (mouseover)="isInfoOpen = true"
    (mouseout)="isInfoOpen = false"
    cdkOverlayOrigin
    #infoTrigger="cdkOverlayOrigin"
    mat-icon-button
    aria-label="next rule execution">
    <mat-icon>info</mat-icon>
  </button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="infoTrigger"
    [cdkConnectedOverlayOpen]="isInfoOpen">
    <div
      class="info"
      *ngIf="timeLeft$ | async as t"
      [ngClass]="calculateClasses()">
      Rules scheduled to be deployed today at
      <ng-container
        *ngFor="let date of datesArray; let isLast = last; let i = index">
        {{ date | date: 'HH:mm' }}{{ isLast ? '' : ', ' }}
      </ng-container>
    </div>
  </ng-template>
</div>
