import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BrowserStorageService } from './browser-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  get token(): Observable<string | null> {
    return this.tokenSubject.asObservable();
  }
  private tokenSubject = new BehaviorSubject<string | null>(
    this.storage.get('token')
  );
  private _sesametokenSubject = new BehaviorSubject<string | null>(
    this.storage.get('sesametoken')
  );
  get sesametoken(): Observable<string | null> {
    return this._sesametokenSubject.asObservable();
  }
  constructor(private storage: BrowserStorageService) {}

  get(): string | null {
    return this.tokenSubject.value;
  }

  getSesame(): string | null {
    return this._sesametokenSubject.value;
  }

  set(token: string, sesame_token: string): void {
    this.tokenSubject.next(token);
    this._sesametokenSubject.next(sesame_token);
    this.storage.remove('token');
    this.storage.remove('sesametoken');
  }

  persist(token: string, sesame_token: string): void {
    this.tokenSubject.next(token);
    this._sesametokenSubject.next(sesame_token);
    this.storage.set('token', token);
    this.storage.set('sesametoken', sesame_token);
  }

  remove(): void {
    this.tokenSubject.next(null);
    this._sesametokenSubject.next(null);
    this.storage.remove('token');
    this.storage.remove('sesametoken');
  }
}
