import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { AuthModule } from '../auth/auth.module';
import { DarkModeModule } from '../dark-mode/dark-mode.module';
import { RuleCountdownComponent } from '../rule/rule-countdown/rule-countdown.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DarkModeModule,
    AuthModule,
    FlexLayoutModule,
    MatButtonModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    RuleCountdownComponent,
    MatTooltipModule,
    MatDividerModule,
    HomeComponent,
    MainMenuComponent,
    UserMenuComponent,
    DashboardComponent,
  ],
})
export class HomeModule {}
