import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { TokenService } from '../token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private token: TokenService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.token.get();
    const sesame = this.token.getSesame();
    if (token && this.shouldSendToken(request)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    if (sesame && this.shouldSendSesameToken(request)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${sesame}`,
        },
      });
    }
    return next.handle(request);
  }

  private shouldSendToken(request: HttpRequest<unknown>): boolean {
    return (
      request.url.startsWith(environment.api) && !request.url.endsWith('/login')
    );
  }
  private shouldSendSesameToken(request: HttpRequest<unknown>): boolean {
    return (
      request.url.startsWith(environment.telescope) &&
      !request.url.endsWith('/login')
    );
  }
}
