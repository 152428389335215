import { Component } from '@angular/core';
import { MatAnchor, MatIconAnchor } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';

import { NeedPermissionAnchorDirective } from '../../auth/need-permission-anchor.directive';
import { DarkModeToggleComponent } from '../../dark-mode/dark-mode-toggle/dark-mode-toggle.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'glx-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    MatAnchor,
    NeedPermissionAnchorDirective,
    RouterLink,
    MatIconAnchor,
    MatTooltip,
    MatDivider,
    DarkModeToggleComponent,
    UserMenuComponent,
  ],
})
export class DashboardComponent {}
