import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import {
  MatListItem,
  MatListItemIcon,
  MatListItemTitle,
  MatListSubheaderCssMatStyler,
  MatNavList,
} from '@angular/material/list';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { NeedPermissionAnchorDirective } from '../../auth/need-permission-anchor.directive';

@Component({
  selector: 'glx-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatNavList,
    MatListSubheaderCssMatStyler,
    MatListItem,
    NeedPermissionAnchorDirective,
    RouterLink,
    RouterLinkActive,
    MatListItemTitle,
    MatIcon,
    MatListItemIcon,
    MatDivider,
  ],
})
export class MainMenuComponent {}
