import { NgModule } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule(/* TODO(standalone-migration): clean up removed NgModule class manually. 
{
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HomeModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatSnackBarModule,
    MaintenanceComponent,
  ],
  providers: [
    provideMomentDateAdapter(MY_DATE_FORMATS, { useUtc: true }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MergePatchJsonInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
} */)
export class AppModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.matIconRegistry
      .addSvgIcon(
        'logo',
        this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/logo.svg')
      )
      .addSvgIcon(
        'microphone',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/microphone.svg'
        )
      )
      .addSvgIcon(
        'artist',
        this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/artist.svg')
      );
    DefaultValueAccessor.prototype.registerOnChange = function (
      fn: (_: string | null) => void
    ): void {
      this.onChange = (value: string | null) => {
        fn(value === '' ? null : value);
      };
    };
  }
}
