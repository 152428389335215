import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconAnchor, MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import {
  MatSidenav,
  MatSidenavContainer,
  MatSidenavContent,
} from '@angular/material/sidenav';
import { MatToolbar } from '@angular/material/toolbar';
import { MatTooltip } from '@angular/material/tooltip';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import {
  filter,
  map,
  mergeMap,
  Observable,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';

import { NeedPermissionAnchorDirective } from '../auth/need-permission-anchor.directive';
import { routeSwap } from '../core/animations';
import { DarkModeToggleComponent } from '../dark-mode/dark-mode-toggle/dark-mode-toggle.component';
import { RuleCountdownComponent } from '../rule/rule-countdown/rule-countdown.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

@Component({
  selector: 'glx-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [routeSwap],
  standalone: true,
  imports: [
    MatSidenavContainer,
    MatSidenav,
    MatToolbar,
    MatIcon,
    MainMenuComponent,
    MatSidenavContent,
    MatIconButton,
    MatDivider,
    RuleCountdownComponent,
    MatIconAnchor,
    NeedPermissionAnchorDirective,
    MatTooltip,
    RouterLink,
    DarkModeToggleComponent,
    UserMenuComponent,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class HomeComponent implements OnDestroy, OnInit {
  private _destroyed = new Subject<void>();
  mediumScreenState: Observable<BreakpointState>;
  showRuleCountdown = false;
  title!: string | undefined;
  animation!: number | undefined;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    breakpointObserver: BreakpointObserver
  ) {
    this.mediumScreenState = breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
    ]);
  }
  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this._destroyed),
        filter(event => event instanceof NavigationEnd),
        startWith(this.router)
      )
      .pipe(
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.title = event['title'];
        this.showRuleCountdown = event['neededPermission']
          ?.toLowerCase()
          ?.includes('fusio');
        this.animation = event['animation'];
      });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
