import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';

import { BrowserStorageService } from '../core/browser-storage.service';

export const DARK_MODE_MEDIA_QUERY = '(prefers-color-scheme: dark)';

@Injectable()
export class DarkModeService {
  private readonly storageKey = 'darkMode';

  private readonly activated: BehaviorSubject<boolean>;

  get activationChanges(): Observable<boolean> {
    return this.activated.asObservable().pipe(distinctUntilChanged());
  }

  get isActivated(): boolean {
    return this.activated.value;
  }

  constructor(
    private storage: BrowserStorageService,
    private breakpointObserver: BreakpointObserver
  ) {
    const storedValue = storage.get<boolean>(this.storageKey);
    const darkModeActivated =
      storedValue !== null
        ? storedValue
        : this.breakpointObserver.isMatched(DARK_MODE_MEDIA_QUERY);

    this.activated = new BehaviorSubject<boolean>(darkModeActivated);
    this.activationChanges.subscribe(activated => {
      this.storage.set(this.storageKey, activated);
    });
  }

  activate(): void {
    this.activated.next(true);
  }

  deactivate(): void {
    this.activated.next(false);
  }

  toggle(): void {
    this.isActivated ? this.deactivate() : this.activate();
  }
}
