<div class="logo">
  <mat-icon svgIcon="logo"></mat-icon>
</div>
<h2>GALAXY</h2>
<mat-list>
  <mat-list-item>
    <div class="status" mat-line color="primary">
      {{ 'under maintenance !'.toUpperCase() }}
    </div>
  </mat-list-item>
</mat-list>
<mat-divider></mat-divider>
<button mat-flat-button color="primary" routerLink="/sign-in">
  BACK TO LOGIN
</button>
