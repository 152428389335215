import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';

import { AppComponent } from './app/app.component';
import { AppModule, MY_DATE_FORMATS } from './app/app.module';
import { AppRoutingModule } from './app/app-routing.module';
import { ErrorInterceptor } from './app/core/interceptors/error.interceptor';
import { MergePatchJsonInterceptor } from './app/core/interceptors/merge-patch-json.interceptor';
import { TokenInterceptor } from './app/core/interceptors/token.interceptor';
import { HomeModule } from './app/home/home.module';
import { NetworkInterceptor } from './app/interceptors/network.interceptor';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      HomeModule,
      AppModule,
      MatProgressBarModule,
      MatIconModule,
      MatButtonModule,
      MatListModule,
      MatSnackBarModule
    ),
    provideMomentDateAdapter(MY_DATE_FORMATS, { useUtc: true }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MergePatchJsonInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch(err => console.error(err));
