import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import {
  MatMenu,
  MatMenuContent,
  MatMenuItem,
  MatMenuTrigger,
} from '@angular/material/menu';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'glx-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  standalone: true,
  imports: [
    MatButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    AsyncPipe,
  ],
})
export class UserMenuComponent {
  readonly user = this.auth.user;

  constructor(private auth: AuthService) {}

  logout(): void {
    this.auth.logout();
  }
}
