import { DOCUMENT, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDivider } from '@angular/material/divider';
import {
  MatError,
  MatFormField,
  MatLabel,
  MatPrefix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, iif, map, take } from 'rxjs';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner.service';

import { DarkModeToggleComponent } from '../../dark-mode/dark-mode-toggle/dark-mode-toggle.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'glx-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinner,
    NgClass,
    ExtendedModule,
    MatIcon,
    MatDivider,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatCheckbox,
    MatButton,
    MatPrefix,
    NgTemplateOutlet,
    DarkModeToggleComponent,
  ],
})
export class SignInComponent implements OnInit {
  isLoading = false;

  form = this.fb.nonNullable.group({
    login: ['', Validators.required],
    password: ['', Validators.required],
    rememberMe: [true],
  });

  constructor(
    private loadingService: LoadingSpinnerService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    @Inject(MatSnackBar) public snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document
  ) {}
  ngOnInit(): void {
    this.route.snapshot.queryParamMap.has('dv_token') && this.callback();
  }
  callback() {
    this.loadingService.show();
    this.auth
      .ssoCallback()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe({
        next: () => this.router.navigate(['/']),
        error: ({ error }) => {
          this.snackBar.open(`Authentication failed : ${error.message}`, 'OK', {
            panelClass: 'error-snack-bar',
            duration: 3000,
          });
        },
      });
  }
  sso() {
    this.auth
      .ssoInit()
      .pipe(
        take(1),
        map(({ redirectUrl }) => redirectUrl)
      )
      .subscribe({
        next: redirect => (this.document.location.href = redirect),
        error: ({ error }) => {
          this.snackBar.open(`Authentication failed : ${error.message}`, 'OK', {
            panelClass: 'error-snack-bar',
            duration: 3000,
          });
        },
      });
  }

  signIn() {
    const { login, password, rememberMe } = this.form.getRawValue();
    this.isLoading = true;

    iif(
      () => rememberMe,
      this.auth.signInAndRemember(login, password),
      this.auth.signIn(login, password)
    )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          const redirectUrl =
            this.route.snapshot.queryParamMap.get('redirectTo') || '/';
          this.router.navigate([redirectUrl]);
        },
        error: ({ error }) => {
          this.snackBar.open(`Authentication failed : ${error.message}`, 'OK', {
            panelClass: 'error-snack-bar',
            duration: 3000,
          });
        },
      });
  }
}
