<mat-sidenav-container>
  <mat-sidenav
    fixedInViewport
    fixedTopGap="0"
    fixedBottomGap="0"
    [mode]="(mediumScreenState | async)?.matches ? 'over' : 'side'"
    [opened]="!(mediumScreenState | async)?.matches"
    class="mat-elevation-z4 dark"
    #menu>
    <mat-toolbar class="logo-toolbar">
      <mat-icon class="logo" svgIcon="logo"></mat-icon>
      <span class="site-name">Galaxy</span>
    </mat-toolbar>
    <glx-main-menu></glx-main-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="mat-elevation-z2 galaxy-toolbar">
      <button mat-icon-button (click)="menu.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <h1>{{ title || '' }}</h1>

      <span class="separator"></span>

      @if (showRuleCountdown) {
        <mat-divider vertical></mat-divider>
        <glx-rule-countdown></glx-rule-countdown>
      }
      <mat-divider vertical></mat-divider>
      <div class="documentation-action-button-wrapper">
        <a
          matTooltip="Classifications"
          mat-icon-button
          routerLink="/documentation"
          need-permission="GALAXY_READER">
          <mat-icon class="material-symbols-outlined">policy_alert</mat-icon>
        </a>

        <a
          matTooltip="Documentation"
          mat-icon-button
          href="https://coa.dvtech.io/pages/viewpage.action?pageId=1000082374735">
          <mat-icon class="material-symbols-outlined">dictionary</mat-icon>
        </a>
      </div>

      <mat-divider vertical></mat-divider>
      <glx-dark-mode-toggle></glx-dark-mode-toggle>
      <mat-divider vertical></mat-divider>

      <glx-user-menu></glx-user-menu>
    </mat-toolbar>
    <main [@routeSwap]="animation">
      <router-outlet #outlet="outlet"></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
