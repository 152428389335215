import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs';

@Component({
  selector: 'glx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, MatProgressBar, RouterOutlet, AsyncPipe],
})
export class AppComponent {
  isLoading$ = this.router.events.pipe(
    filter(
      event =>
        event instanceof NavigationStart ||
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
    ),
    map(event => event instanceof NavigationStart),
    distinctUntilChanged()
  );

  constructor(private router: Router) {}
}
