import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';

import { DarkModeModule } from '../dark-mode/dark-mode.module';
import { NeedPermissionAnchorDirective } from './need-permission-anchor.directive';
import { NeedPermissionButtonDirective } from './need-permission-button.directive';
import { SignInComponent } from './sign-in/sign-in.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DarkModeModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatButtonModule,
    MatCheckboxModule,
    FlexLayoutModule,
    NeedPermissionAnchorDirective,
    NeedPermissionButtonDirective,
    SignInComponent,
  ],
  exports: [NeedPermissionAnchorDirective, NeedPermissionButtonDirective],
})
export class AuthModule {}
