import { Component, ViewEncapsulation } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import { DarkModeService } from '../dark-mode.service';

@Component({
  selector: 'glx-dark-mode-toggle',
  templateUrl: './dark-mode-toggle.component.html',
  styleUrls: ['./dark-mode-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatIconButton, MatIcon],
})
export class DarkModeToggleComponent {
  constructor(public darkMode: DarkModeService) {}
}
